import React from 'react';

import { IChallengePageProps } from './interfaces';
import { useSettings } from '@wix/tpa-settings/react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { st, classes } from './Widget.st.css';
import { UserState, useUser } from '../../../contexts/User/UserContext';
import settingsParams from '../settingsParams';
import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import {
  isForcedPreviewParticipant,
  isForcedPreviewVisitor,
} from '../../../selectors/isForcedPreview';
import { ChallengeCompletedModal } from '../../../components-shared/ChallengeCompletedModal/ChallengeCompletedModal';
import { LeaveChallengeModalProvider } from '../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { withProviders } from '../../../contexts/main/withProviders';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { isFullscreenMode } from '../../../contexts/Fullscreen/FullscreenProvider';
import { FCWithChildren } from '@wix/challenges-web-library';
import { Challenges } from '../../../editor/types/Experiments';
import { ParticipantRoles } from '../../../types/programs';
import { isUserJoinedAlready } from '../../../contexts/User/helpers/userTypeHandlers';

const SidebarLayoutForParticipant = loadable(() => {
  return import(
    /* webpackChunkName: "ProgramsSidebarParticipantPage" */ '../../ParticipantPage/Widget/views/SidebarLayout/SidebarLayoutForParticipant'
  );
});

const VisitorPage = loadable(() => {
  return import(
    /* webpackChunkName: "ProgramsVisitorPage" */ './views/VisitorPage/VisitorPage'
  );
});

export const ChallengePage: FCWithChildren<IChallengePageProps> = (props) => {
  const { t } = useTranslation();
  const { isEditor, isPreview, isViewer, isMobile, language } =
    useEnvironment();
  const { experiments } = useExperiments();
  const isParticipantPageEnabled =
    experiments.enabled(Challenges.enableParticipantPageInEditor) &&
    props.isParticipantPageAvailable;

  const user = useUser();
  const settings = useSettings();
  const pageRef = React.useRef<HTMLDivElement>();

  const settingsProp: IChallengeSettings = {} as any;
  Object.keys(settingsParams).map((key) => {
    settingsProp[key] = settings.get(settingsParams[key]);
  });

  const { userType, challengeData } = props;

  /*
    We show a Participant page is case of:
      - this is regular Participant Page;
      - this is Editor and owner work with settings for Participant page;
      - this is preview of Participant Page from BM.
    Also, we need to force show Visitor page in case of owner wants to preview it from BM.
   */
  const isParticipantPage_ = isUserJoinedAlready(userType);
  const isEditorPreview =
    settingsProp.editorParticipantRole === ParticipantRoles.Participant &&
    (isEditor || isPreview);
  const renderParticipantPage =
    (isParticipantPage_ ||
      isEditorPreview ||
      isForcedPreviewParticipant(props.query)) &&
    !isForcedPreviewVisitor(props.query) &&
    !isParticipantPageEnabled;

  React.useEffect(() => {
    props.hideLoader && props.hideLoader();
  }, []);

  if (props.isForcedBlankPage) {
    return null;
  } else if (!challengeData?.challenge && isViewer) {
    if (userType === UserState.VISITOR) {
      props.promptLogin();
    }

    return <p className={classes.noAccess}>{t('challenge.no-access')}</p>;
  }

  return (
    <div
      ref={pageRef}
      data-hook="program-page-main"
      className={st(classes.root)}
    >
      <div data-hook="challenge-page">
        {renderParticipantPage ? ( // todo move it to separate file "JoinedParticipantPage"
          <>
            <LeaveChallengeModalProvider
              leaveProgram={user.leaveTheChallenge}
              showModal={null}
            >
              <SidebarLayoutForParticipant
                {...props}
                isEditor={isEditor}
                isMobile={isMobile}
                t={t}
                pageRef={pageRef}
                settings={settingsProp as any}
                lng={language}
                isFullscreen={isFullscreenMode()}
              />
            </LeaveChallengeModalProvider>
            <ChallengeCompletedModal />
          </>
        ) : (
          <VisitorPage
            t={t}
            lng={language}
            {...props}
            settings={settingsProp as any}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

ChallengePage.displayName = 'ChallengePageContainer';

export default withProviders(ChallengePage);
